import styled from "styled-components";
import { STYLES_VIEWPORTS_VALUES } from "../../../utils/constants";

export const CardContainer = styled.li`
  flex-direction: row;
  list-style: none;
  margin: var(--dui-size-space-3x);
  flex: 1;
  max-width: 33%;
  min-width: 280px;
  @media screen and (max-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM - 1}px) {
    max-width: 100%;
    width: 100%;
    margin: var(--dui-size-space-3x) 0;
  }
`;
