export const REROUTING_DATA_TESTIDS = {
  region: {
    link: "rerouting-shipment-product-region-link",
    notice: "rerouting-shipment-product-region-notice",
  },
  threshold: {
    link: "rerouting-shipment-product-threshold-link",
    notice: "rerouting-shipment-product-threshold-notice",
  },
};

export const TRACKING_KEYS = {
  reroutingRegion:
    "regularShipmentLP.interactions.rerouting.reroutingRegionProductDetailsLinkClicked",
  reroutingThreshold:
    "regularShipmentLP.interactions.rerouting.reroutingThresholdProductDetailsLinkClicked",
};
