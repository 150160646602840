import PropTypes from "prop-types";
import { DhlValidationFeedback } from "@dhl-official/react-library";
import React from "react";
import { FormattedMessage } from "react-intl";
import { compose, prop, always } from "ramda";
import FF from "../../../../utils/functional/form-field";
import {
  Container,
  Info,
  List,
  Item,
  Option,
  OptionContent,
  Title,
  SubTitle,
} from "./RegionOptions.styles";

export const RegionOptions = ({
  className,
  intl,
  isInvalid,
  options,
  onChange,
  product,
  regions,
  selectedRegions,
  trackingPath,
}) => (
  <Container>
    <Info dataTestid={`${product}-shipmentScaleInfo-heading`}>
      <FormattedMessage id="FS2.CardShipmentScale.shipmenScaleInfo" />
    </Info>
    <List className={className}>
      {options.map((region) => (
        <Item
          key={region}
          className="checkbox-item"
          data-testid={`shipmentProduct_${product}_region_${region}_listItem`}
        >
          <Option
            dataTestid={`shipmentProduct_${product}_region_${region}`}
            dataTracking={`${trackingPath}.shipmentScales`}
            checked={selectedRegions.includes(region)}
            name={region}
            onDhlChange={() => onChange(region)}
            value={region}
            validation={FF.case(
              {
                invalid: () => ({
                  type: "invalid",
                  message: "",
                }),
                _: always(undefined),
              },
              regions
            )}
          >
            <OptionContent>
              <Title dataTestid={`shipmentProduct-${product}-${region}-label`}>
                <FormattedMessage
                  id={`FS2.CardShipmentScale.shipmentScale_${region}`}
                />
              </Title>
              <SubTitle
                dataTestid={`shipmentProduct-${product}-${region}-explanation`}
              >
                {`(${intl.formatMessage({
                  id: `FS2.CardShipmentScale.shipmentScale_${region}_Explanation`,
                })})`}
              </SubTitle>
            </OptionContent>
          </Option>
        </Item>
      ))}
    </List>
    {isInvalid && (
      <DhlValidationFeedback
        dataTestid={`shipmentProduct-${product}-region-validationMessage`}
        validation={{
          type: "invalid",
          message: intl.formatMessage({
            id: `${compose(
              prop("feedbackMessageId"),
              FF.getFeedback
            )(regions)}`,
          }),
        }}
      />
    )}
  </Container>
);

RegionOptions.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  product: PropTypes.string.isRequired,
  regions: PropTypes.object,
  selectedRegions: PropTypes.array,
  trackingPath: PropTypes.string.isRequired,
};

RegionOptions.defaultProps = {
  selectedRegions: [],
};
