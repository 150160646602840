import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DhlList, DhlListItem, DhlText } from "@dhl-official/react-library";
import styled from "styled-components";
import CardSelectable from "../../../Cards/Selectable";
import {
  STYLES_CUSTOM_BREAKPOINT_CARDS,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
  STYLES_VIEWPORTS_VALUES,
} from "../../../../utils/constants";
import { trackContentInteraction } from "../../../../utils/track-utf";
import { calculateTitleMinHeight, isTitleTooLong } from "../../helpers";

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--dui-size-space-8x) 0 0 0;

  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    flex-direction: row;
  }
`;

const ProductLabel = styled(DhlText).attrs({ weight: 700 })`
  & span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
    word-break: break-word;
    margin-bottom: var(--dui-size-space-4x);
  }

  & span small {
    display: flex;
  }
  @media screen and (min-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM}px) and (max-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM +
    120}px) {
    &.expand-me span {
      min-height: ${({ $maxTitleLength }) =>
        calculateTitleMinHeight($maxTitleLength, "1.5rem", "1.33")};
    }
  }
`;

const ProductText = styled.div`
  flex: 1;
  margin-left: 0;
`;

const ProductInfo = styled.div`
  @media screen and (min-width: ${STYLES_CUSTOM_BREAKPOINT_CARDS}) {
    margin-left: var(--dui-size-space-4x);
  }
  ul {
    line-height: initial;
  }

  li {
    color: var(--dui-color-gray-600);
    font-size: var(--dui-size-font-sm);
    margin-bottom: 0;

    &::before {
      width: 10px;
    }

    &::after {
      background-color: var(--dui-color-gray-600);
      height: 3px;
      margin-left: 0px;
      width: 3px;
    }
  }
`;

const ProductInfoList = styled(DhlList)`
  .dhl-list {
    gap: var(--dui-size-space-4x);
    padding-inline-start: 15px;
  }
`;

const ProductInfoListItem = styled(DhlListItem)`
  & li {
    list-style-position: outside;
  }
`;

const ProductInfoListText = styled(DhlText)`
  & span {
    font-size: 1.3rem;
  }
`;

const Small = styled.small`
  display: block;
  font-size: 1em;
  font-weight: 400;

  :before {
    content: "(";
  }

  :after {
    content: ")";
  }
`;
// #endregion

const getHeadline = (label, options) =>
  options.length !== 1 ? (
    label
  ) : (
    <Fragment>
      {label}
      <Small>
        <FormattedMessage
          id={`FS2.CardShipmentScale.shipmentScale_${options[0]}_Only`}
        />
      </Small>
    </Fragment>
  );

const triggerClickTrackingEvent = (product, name) => {
  trackContentInteraction({
    context: product,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_POSITIONS.PRODUCT,
  });
};

const ShipmentProductCard = ({
  checkboxName,
  checkboxValue,
  feedback,
  isChecked,
  isRequired,
  onSetIsChecked,
  product,
  productIcon,
  productInfo,
  productLabel,
  maxTitleLength,
  regions,
  trackingPath,
}) => {
  const onChangeCheckbox = (event) => {
    triggerClickTrackingEvent(
      checkboxValue,
      event.target.checked
        ? UTF_EVENT_NAMES.PRODUCT_CARD_ACTIVATION
        : UTF_EVENT_NAMES.PRODUCT_CARD_DEACTIVATION
    );
    onSetIsChecked([checkboxValue, !event.target.checked]);
  };

  const productInfoText = productInfo.join(", ");

  return (
    <CardSelectable
      dataAriaLabel={`${productLabel}. ${productInfoText}`}
      dataTestid={`${checkboxName}-${checkboxValue}`}
      dataTrackingKeyPath={`${trackingPath}.checked`}
      feedback={feedback}
      isChecked={isChecked}
      isRequired={isRequired}
      name={checkboxName}
      onChange={onChangeCheckbox}
      value={checkboxValue}
      productIcon={productIcon}
    >
      <HeadlineContainer>
        <ProductText>
          <ProductLabel
            aria-hidden="true"
            className={isTitleTooLong(maxTitleLength) ? "expand-me" : ""}
            dataTestid={`${product}-product-label`}
            $maxTitleLength={maxTitleLength}
          >
            {getHeadline(productLabel, regions)}
          </ProductLabel>
          <ProductInfo aria-hidden="true">
            <ProductInfoList>
              {productInfo.map((i, index) => (
                <ProductInfoListItem key={i}>
                  <ProductInfoListText
                    dataTestid={`${product}-product-info-${index}`}
                  >
                    {i}
                  </ProductInfoListText>
                </ProductInfoListItem>
              ))}
            </ProductInfoList>
          </ProductInfo>
        </ProductText>
      </HeadlineContainer>
    </CardSelectable>
  );
};

ShipmentProductCard.propTypes = {
  checkboxName: PropTypes.string.isRequired,
  checkboxValue: PropTypes.string.isRequired,
  feedback: PropTypes.object,
  isChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxTitleLength: PropTypes.number,
  onSetIsChecked: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  productIcon: PropTypes.elementType.isRequired,
  productInfo: PropTypes.array.isRequired,
  productLabel: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  trackingPath: PropTypes.string.isRequired,
};

ShipmentProductCard.defaultProps = {
  feedback: {},
  isChecked: false,
  isRequired: false,
};

export default ShipmentProductCard;
