import "./utils/custom-event-polyfill";
import "whatwg-fetch";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import qs from "querystring";
import { v4 as uuidv4 } from "uuid";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import { defineCustomElements } from "@dhl-official/react-library";
import Entry from "./components/container";
import { isDevMode } from "./utils";
import createStore from "./store";
import GlobalCss from "./styles";
import { setAbTesting } from "./reducers/actions";
import {
  AB_TESTING_CAMPAIGNS,
  CONTENT_DIRECTIONS,
  DATA_CONTAINER_ELEMENT,
  DEFAULT_AB_TESTING,
  DEFAULT_LOCALE,
  DEV_MODES,
  INTEGRATIONS,
  PROFILES,
  QUALIFIER,
  RTL_LOCALES,
  THEMES,
} from "./utils/constants";

const rootNode = document.getElementById(process.env.MOUNT_ID);
const containerNode = document.getElementById(DATA_CONTAINER_ELEMENT);

const getLocale = () =>
  // 1. We try `query-string`
  qs.parse(window.location.search.slice(1)).locale ||
  // 2. Then check if there is a data-attribute in the respective container element
  containerNode?.dataset?.fsLocale ||
  // 3. Then go for `<html lang>`
  document.documentElement.lang ||
  // 4. And fallback to default locale
  DEFAULT_LOCALE;

const locale = getLocale();

export const getProfile = () =>
  qs.parse(window.location.search.slice(1)).profile ||
  containerNode?.dataset?.fsProfile ||
  undefined;

const profile = PROFILES?.[getProfile()];

const getIntegration = () =>
  qs.parse(window.location.search.slice(1)).integration ||
  containerNode?.dataset?.fsIntegration ||
  undefined;

const integration = INTEGRATIONS?.[getIntegration()];

const getTheme = () =>
  qs.parse(window.location.search.slice(1)).theme ||
  containerNode?.dataset?.fsTheme ||
  undefined;

const theme = THEMES?.[getTheme()];

const getQualifier = () =>
  qs.parse(window.location.search.slice(1)).qualifier ||
  containerNode?.dataset?.fsQualifier ||
  profile ||
  undefined;

const qualifier = QUALIFIER?.[getQualifier()];

// Check if the styles should be adapted to RTL rendering
const isRtlEnabled =
  document.documentElement?.dir === CONTENT_DIRECTIONS.RTL ||
  isDevMode(DEV_MODES.RTL) ||
  RTL_LOCALES.includes(locale);

const contentDirection = isRtlEnabled
  ? CONTENT_DIRECTIONS.RTL
  : CONTENT_DIRECTIONS.LTR;

const getAbTesting = () =>
  qs.parse(window.location.search.slice(1)).abTesting ||
  containerNode?.dataset?.fsAbTesting ||
  window.digitalLayer?.gaqPersonalization?.abTesting ||
  undefined;

const abTesting = Object.values(AB_TESTING_CAMPAIGNS).includes(getAbTesting())
  ? getAbTesting()
  : DEFAULT_AB_TESTING;

const pageNameLegacy = window?.dataLayer?.page?.pageInfo?.pageNameLegacy;

const trackingId = uuidv4();

document.dispatchEvent(new CustomEvent("gaq-loaded"));

document.dispatchEvent(
  new CustomEvent("fs-tracking-id-submitted", { detail: { trackingId } })
);

const store = createStore({
  abTesting,
  contentDirection,
  integration,
  pageNameLegacy,
  profile,
  qualifier,
  theme,
  trackingId,
  version: process.env.APPLICATION_VERSION,
});

const handlePersonalization = (detail) => {
  const personalization =
    window.digitalLayer?.gaqPersonalization?.abTesting ||
    detail?.gaqPersonalization?.abTesting;

  if (Object.values(AB_TESTING_CAMPAIGNS).includes(personalization)) {
    store.dispatch(setAbTesting(personalization));
  }
};

rootNode.addEventListener("cjcx-personalize-tool", ({ detail }) => {
  handlePersonalization(detail);
});

const root = createRoot(rootNode);
root.render(
  <Provider store={store}>
    <StyleSheetManager stylisPlugins={isRtlEnabled ? [rtlPlugin] : []}>
      <GlobalCss locale={locale} theme={theme} />
      <Entry locale={locale} />
    </StyleSheetManager>
  </Provider>
);

defineCustomElements();
