import { compose, identity, keys, sortBy } from "ramda";
import qs from "querystring";
import {
  API_REQUEST_HEADERS,
  API_REQUEST_HEADER_VALUES,
  CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES,
  DEFAULT_PROFILE,
  DEFAULT_LOCALE,
  DEV_MODES,
} from "./constants";
import normalizeUrl from "./normalize-url";
import {
  isDevMode,
  isMocked,
  isMockException,
  isLeadSubmitMocked,
  isLocalhost,
} from "./index";

const getLocaleFromQuery = () =>
  qs.parse(window.location.search.slice(1)).locale || DEFAULT_LOCALE;
const getProfileFromQuery = () =>
  qs.parse(window.location.search.slice(1)).profile || DEFAULT_PROFILE;
const getIntegrationFromQuery = () =>
  qs.parse(window.location.search.slice(1)).integration;

const decodeTranslation = (translation) =>
  Object.entries(translation).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value === " " ? "" : value,
    }),
    {}
  );

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const normalizeBusinessUnitDetails = ({ businessUnitId: id, ...details }) => ({
  id,
  ...details,
});

/**
 * Let's reorder the slots API answer a bit to an object
 * with sorted entries of dates containing arrays of possible times
 */
const processCallbackRemoteSlots = (data) => {
  const slotList = data.reduce((acc, entry) => {
    const [slotDate, slotTime] = Object.entries(entry)[0];
    return { ...acc, [slotDate]: [...(acc[slotDate] || []), slotTime] };
  }, {});
  const sortedDays = compose(sortBy(identity), keys)(slotList);
  return sortedDays.reduce((acc, slotDate) => {
    return { ...acc, [slotDate]: slotList[slotDate] };
  }, {});
};

const loadTranslation = (locale) => {
  const [language, country] = locale.toLowerCase().split("-");

  if (isDevMode(DEV_MODES.COPY)) {
    return import("../assets/translations/developer.json").then(
      (module) => module.default
    );
  }

  if (isMocked()) {
    return import("../assets/translations/translations.mock.json").then(
      (module) => {
        const localeFromQuery = getLocaleFromQuery() ?? "en-GB";
        const profileFromQuery = getProfileFromQuery() ?? "FS";
        const integrationFromQuery = getIntegrationFromQuery();
        if (
          integrationFromQuery &&
          !!module.default[profileFromQuery]?.[integrationFromQuery]?.[
            localeFromQuery
          ]
        ) {
          return module.default[profileFromQuery][integrationFromQuery][
            localeFromQuery
          ].labels;
        }
        return (
          module.default[profileFromQuery]?.[localeFromQuery]?.labels ??
          module.default.FS["en-GB"].labels
        );
      }
    );
  }

  return fetch(
    normalizeUrl(
      `${process.env.COPY_BASE_URL}/${country}/${country}-${language}/content-api/frequent-shipment.data.json`
    )
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json())
    .then((res) => decodeTranslation(res.labels));
};

const loadConfiguration = (country, profile, integration) => {
  if (isDevMode(DEV_MODES.SETTINGS)) {
    return import("../assets/configurations/developer.json").then(
      (module) => module.default
    );
  }
  if (isMocked()) {
    return import("../assets/configurations/configurations.mock.json").then(
      (module) => {
        const locale = getLocaleFromQuery() ?? "en-GB";
        const profileFromQuery = getProfileFromQuery() ?? "FS";
        const integrationFromQuery = getIntegrationFromQuery();
        if (integrationFromQuery) {
          return module.default[profileFromQuery][integrationFromQuery][locale];
        }
        if (!module.default[profileFromQuery]?.[locale]) {
          // eslint-disable-next-line no-console
          console.warn(
            `No configuration found for profile ${profileFromQuery} and locale ${locale}`
          );
          return module.default.FS["en-GB"];
        }
        return module.default[profileFromQuery][locale];
      }
    );
  }

  const profileExtension =
    profile && profile !== DEFAULT_PROFILE ? `/profile/${profile}` : "";
  const integrationExtension = integration ? `-${integration}` : "";

  const url = `${process.env.API_BASE_URL}/api/configuration/v2/fs/${country}${profileExtension}${integrationExtension}`;

  return fetch(normalizeUrl(url))
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json());
};

const loadSettings = (country) => {
  if (isDevMode(DEV_MODES.BU_SETTINGS)) {
    return import("../assets/business-lead/developer.json").then(
      (module) => module.default
    );
  }
  if (isMocked()) {
    return import("../assets/business-lead/business-lead.mock.json").then(
      (module) => {
        const profile = getProfileFromQuery() ?? "FS";
        const locale = getLocaleFromQuery() ?? "en-GB";
        const integrationFromQuery = getIntegrationFromQuery();
        if (integrationFromQuery) {
          return module.default[profile][integrationFromQuery][locale];
        }
        if (!module.default[profile]?.[locale]) {
          // eslint-disable-next-line no-console
          console.warn(
            `No business lead configuration found for profile ${profile} and locale ${locale}`
          );
          return module.default.FS["en-GB"];
        }
        return module.default[profile][locale];
      }
    );
  }

  return fetch(
    normalizeUrl(
      `${process.env.API_BASE_URL}/api/cj/admin/businessLeadConfiguration/${country}`
    )
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json());
};

const loadCallbackRemoteSlots = (options) => {
  const { country, correlationId, timeZone } = options;
  if (isDevMode(DEV_MODES.SLOTS)) {
    return import("../assets/slots/developer.json")
      .then((module) => module.default)
      .then((res) => processCallbackRemoteSlots(res.data));
  }

  if (isMocked() && !isMockException("slots")) {
    return delay(2000)
      .then(() => import("../assets/slots/slots.mock.json"))
      .then((module) => {
        const profile = getProfileFromQuery() ?? "FS";
        const locale = getLocaleFromQuery() ?? "en-GB";
        if (!module.default[profile]?.[locale]) {
          // eslint-disable-next-line no-console
          console.warn(
            `No slots found for profile ${profile} and locale ${locale}`
          );
          return module.default.FS["en-GB"];
        }
        return module.default[profile][locale];
      })
      .then((res) => processCallbackRemoteSlots(res.data));
  }

  return fetch(
    normalizeUrl(
      `${
        process.env.API_BASE_URL
      }/api/regularshipment/v2/slots?countryCode=${country}&timeZone=${encodeURIComponent(
        timeZone
      )}`
    ),
    {
      method: "GET",
      headers: {
        [API_REQUEST_HEADERS.APP_CODE]: API_REQUEST_HEADER_VALUES.APP_CODE,
        [API_REQUEST_HEADERS.X_CORRELATION_ID]: correlationId,
      },
    }
  )
    .then((res) =>
      res.ok &&
      res?.status?.toString() ===
        CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES.SUCCESS
        ? res
        : Promise.reject(res)
    )
    .then((res) => res.json())
    .then((res) => processCallbackRemoteSlots(res.data));
};

const postConnectorClicks = (body) => {
  if (isMocked()) {
    // eslint-disable-next-line no-console
    console.log("Mocked connector clicks logging");
    return null;
  }

  return (
    fetch(
      normalizeUrl(`${process.env.API_BASE_URL}/api/regularshipment/v2/click`),
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          [API_REQUEST_HEADERS.CONTENT_TYPE]: "application/json",
        },
      }
    )
      .then((res) => (!res.ok ? Promise.reject(res) : res))
      // eslint-disable-next-line no-console
      .catch((e) => isLocalhost && console.log(e))
  );
};

const postLead = (body, correlationIds) => {
  if (isLeadSubmitMocked()) {
    return import("../assets/lead-submit/lead-submit.mock.json")
      .then((module) => {
        const profile = getProfileFromQuery() ?? "FS";
        const locale = getLocaleFromQuery() ?? "en-GB";
        return module.default[profile][locale];
      })
      .then((res) => ({
        ...res,
        businessUnitDetails: res.businessUnitDetails.map(
          normalizeBusinessUnitDetails
        ),
      }));
  }
  return fetch(
    normalizeUrl(`${process.env.API_BASE_URL}/api/regularshipment/v2`),
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        [API_REQUEST_HEADERS.CONTENT_TYPE]: "application/json",
        [API_REQUEST_HEADERS.X_B3_SPAN_ID]: correlationIds?.spanId || "",
        [API_REQUEST_HEADERS.X_B3_TRACE_ID]: correlationIds?.traceId || "",
        [API_REQUEST_HEADERS.TRACEPARENT]: correlationIds?.traceparent || "",
      },
    }
  )
    .then((res) => (!res.ok ? Promise.reject(res) : res))
    .then((res) => res.json())
    .then((res) => ({
      ...res,
      businessUnitDetails: res.businessUnitDetails.map(
        normalizeBusinessUnitDetails
      ),
    }));
};

export default {
  loadCallbackRemoteSlots,
  loadConfiguration,
  loadSettings,
  loadTranslation,
  postLead,
  postConnectorClicks,
};
