import { prop, compose } from "ramda";
import {
  INIT_SCHEDULER,
  SET_SCHEDULER_DISPLAY,
  LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE,
  LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS,
  LOAD_PHONE_NUMBER_VALIDATION_LIB,
  NOTIFY_DECIBEL_FORM_REFRESH,
  NOTIFY_DECIBEL_FORM_SUBMIT,
  SET_ACTIVE_STEP,
  TRIGGER_CONTENT_INTERACTION_TRACKING,
  TRIGGER_PAGE_VIEW_TRACKING,
} from "../../../../actions";
import {
  GLOBAL_OBJECT,
  PAGE_VIEW_TITLES,
  PROFILES,
  STEPS,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_TYPES,
  VALIDATE_PHONE_NUMBER_LIB,
} from "../../../../utils/constants";
import { postLeadEffect } from "../../../lead/business-units/actions";
import {
  validateShipmentProductEffect,
  validateProductDetailsEffect,
} from "../../../lead/shipment-product/actions";
import { validateContactFormEffect } from "../../../lead/contact-information/actions";
import {
  trackContentInteraction,
  trackPageView,
} from "../../../../utils/track-utf";
import RD from "../../../../utils/functional/remote-data";
import * as reducers from "../../..";

export const setActiveStep = (payload) => ({
  payload,
  type: SET_ACTIVE_STEP,
});

const initScheduler = (payload) => ({
  payload,
  type: INIT_SCHEDULER,
});

const setSchedulerDisplay = (payload) => ({
  payload,
  type: SET_SCHEDULER_DISPLAY,
});

const loadPhoneNumberValidationLib = () => ({
  type: LOAD_PHONE_NUMBER_VALIDATION_LIB,
});

const loadPhoneNumberValidationLibSuccess = (payload) => ({
  payload,
  type: LOAD_PHONE_NUMBER_VALIDATION_LIB_SUCCESS,
});

const loadPhoneNumberValidationLibFailure = (payload) => ({
  error: true,
  payload,
  type: LOAD_PHONE_NUMBER_VALIDATION_LIB_FAILURE,
});

const notifyDecibelFormRefresh = () => ({
  type: NOTIFY_DECIBEL_FORM_REFRESH,
});

const notifyDecibelFormSubmit = () => ({
  type: NOTIFY_DECIBEL_FORM_SUBMIT,
});

const triggerPageViewTracking = () => ({
  type: TRIGGER_PAGE_VIEW_TRACKING,
});

const triggerContentInteractionTracking = () => ({
  type: TRIGGER_CONTENT_INTERACTION_TRACKING,
});

const loadPhoneNumberValidationLibEffect = () => (
  dispatch,
  _,
  { getLibPhoneNumber }
) => {
  dispatch(loadPhoneNumberValidationLib());

  getLibPhoneNumber()
    .then((module) => {
      window[GLOBAL_OBJECT] = window[GLOBAL_OBJECT] || {};
      window[GLOBAL_OBJECT][VALIDATE_PHONE_NUMBER_LIB] =
        module.isValidNumberForRegion;

      dispatch(
        loadPhoneNumberValidationLibSuccess(
          [GLOBAL_OBJECT, VALIDATE_PHONE_NUMBER_LIB].join(".")
        )
      );
    })
    .catch((error) => {
      dispatch(loadPhoneNumberValidationLibFailure(error));
    });
};

const triggerPageViewTrackingEffect = (pageId) => (dispatch, getState) => {
  dispatch(triggerPageViewTracking());
  const pageNameLegacy = reducers.getPageNameLegacy(getState());
  trackPageView(pageNameLegacy, pageId);
};

const triggerContentInteractionTrackingEffect = (utfName) => (
  dispatch,
  getState
) => {
  dispatch(triggerContentInteractionTracking());
  const bu = reducers.getConnectorsBU(getState());
  trackContentInteraction({
    context: bu,
    name: utfName,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: UTF_EVENT_POSITIONS.CONNECTORS,
  });
};

const goToLastStepEffect = () => (dispatch, getState) => {
  const shouldShowConnectorsStep = reducers.getShouldShowConnectorsStep(
    getState()
  );
  const connectorsBU = reducers.getConnectorsBU(getState());

  if (connectorsBU) {
    dispatch(initScheduler(connectorsBU));
  }

  if (shouldShowConnectorsStep) {
    dispatch(setActiveStep(STEPS.CONNECTORS));
    dispatch(triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.CONNECTORS));
  } else {
    /**
     * If callback is the only connector, do not show connectors step but display
     * the scheduler in the regular contact form directly
     */
    if (reducers.getIsCallbackSingleConnector(getState())) {
      dispatch(setSchedulerDisplay(true));
      dispatch(
        triggerContentInteractionTrackingEffect(
          UTF_EVENT_NAMES.SCHEDULE_A_CALLBACK
        )
      );
    }

    dispatch(setActiveStep(STEPS.CONTACT_FORM));
    dispatch(triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.CONTACT_FORM));
  }
};

const notifyDecibelFormRefreshEffect = () => (dispatch, _, { decibel }) => {
  dispatch(notifyDecibelFormRefresh());
  decibel.notifyFormRefresh();
};

export const notifyDecibelFormSubmitEffect = () => (
  dispatch,
  _,
  { decibel }
) => {
  dispatch(notifyDecibelFormSubmit());
  decibel.notifyFormSubmit();
};

export const goToNextStepEffect = () => (dispatch, getState) => {
  const profile = reducers.getProfile(getState());
  const activeStep = reducers.getActiveStep(getState());
  const matchedBUs = reducers.getMatchedBUs(getState());
  switch (activeStep) {
    case STEPS.INTRO: {
      const showProductDetailsPage = compose(
        RD.withDefault(false),
        RD.map(prop("showProductDetailsPage")),
        reducers.getFeatureFlags
      )(getState());

      if (showProductDetailsPage) {
        dispatch(setActiveStep(STEPS.PRODUCT_DETAILS));
        dispatch(
          triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.PRODUCT_DETAILS)
        );
        dispatch(notifyDecibelFormRefreshEffect());
      } else {
        dispatch(loadPhoneNumberValidationLibEffect());
        dispatch(goToLastStepEffect());
      }

      break;
    }

    case STEPS.PRODUCT: {
      if (dispatch(validateShipmentProductEffect())) {
        dispatch(setActiveStep(STEPS.PRODUCT_DETAILS));
        dispatch(
          triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.PRODUCT_DETAILS)
        );
        dispatch(notifyDecibelFormRefreshEffect());
      }

      break;
    }

    case STEPS.PRODUCT_DETAILS: {
      switch (profile) {
        case PROFILES.FSX: {
          if (dispatch(validateProductDetailsEffect())) {
            dispatch(loadPhoneNumberValidationLibEffect());
            dispatch(goToLastStepEffect());
          }
          break;
        }

        case PROFILES.FSDEC: {
          if (dispatch(validateProductDetailsEffect())) {
            if (matchedBUs.length >= 1) {
              dispatch(loadPhoneNumberValidationLibEffect());
              dispatch(goToLastStepEffect());
            }
          }
          break;
        }

        case PROFILES.FS:
        default: {
          if (dispatch(validateProductDetailsEffect())) {
            if (matchedBUs.length >= 1) {
              dispatch(setActiveStep(STEPS.SERVICES));
              dispatch(
                triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.SERVICES)
              );
              dispatch(notifyDecibelFormRefreshEffect());
            }
          }
          break;
        }
      }

      break;
    }

    case STEPS.SERVICES: {
      dispatch(loadPhoneNumberValidationLibEffect());
      dispatch(goToLastStepEffect());

      break;
    }

    case STEPS.CONNECTORS:
    case STEPS.CONTACT_FORM: {
      if (dispatch(validateContactFormEffect())) {
        dispatch(postLeadEffect());
        dispatch(triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.THANK_YOU));
        dispatch(notifyDecibelFormSubmitEffect());
      }

      break;
    }

    default:
      break;
  }
};

export const goToPreviousStepEffect = () => (dispatch, getState) => {
  const profile = reducers.getProfile(getState());
  const activeStep = reducers.getActiveStep(getState());

  switch (activeStep) {
    case STEPS.CONNECTORS:
    case STEPS.CONTACT_FORM: {
      switch (profile) {
        case PROFILES.FSDEC:
        case PROFILES.FSX: {
          dispatch(setActiveStep(STEPS.PRODUCT_DETAILS));
          dispatch(setSchedulerDisplay(false));
          dispatch(
            triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.PRODUCT_DETAILS)
          );
          break;
        }

        case PROFILES.FS:
        default: {
          dispatch(setActiveStep(STEPS.SERVICES));
          dispatch(setSchedulerDisplay(false));
          dispatch(triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.SERVICES));
          break;
        }
      }

      break;
    }

    case STEPS.SERVICES: {
      dispatch(setActiveStep(STEPS.PRODUCT_DETAILS));
      dispatch(triggerPageViewTrackingEffect(PAGE_VIEW_TITLES.PRODUCT_DETAILS));
      break;
    }

    case STEPS.PRODUCT_DETAILS: {
      dispatch(setActiveStep(STEPS.PRODUCT));
      /**
       * No section id specified for this tracking call because we want to count it as
       * a regular page view like the initial page load without any custom section at all
       */
      dispatch(triggerPageViewTrackingEffect());
      break;
    }

    default:
      break;
  }
};
