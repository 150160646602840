import PropTypes from "prop-types";
import { DhlValidationFeedback } from "@dhl-official/react-library";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { always } from "ramda";
import FF from "../../../../utils/functional/form-field";
import {
  Container,
  Info,
  Item,
  Option,
  OptionContent,
  Title,
  SubTitle,
} from "./ParcelOptions.styles";

import { options, translations } from "./ParcelOptions.constants";

export const ParcelOptions = ({
  className,
  onChange,
  formField,
  product,
  isInvalid,
}) => {
  const intl = useIntl();
  const selectedOptions = FF.getValue(formField.options);
  return (
    <Container className={className}>
      <Info>
        <FormattedMessage id="FS2.CardShipmentProduct.parcelOptionsInfo" />
      </Info>
      {options.map((option) => {
        const translate = translations[option];
        return (
          <Item key={option} className="checkbox-item">
            <Option
              dataTestid={`parcelOptions-${option}`}
              checked={selectedOptions[option]}
              name={option}
              onDhlChange={() => onChange(option)}
              value={option}
              validation={FF.case(
                {
                  invalid: () => ({
                    type: "invalid",
                    message: "",
                  }),
                  valid: () => ({
                    type: "valid",
                    message: "",
                  }),
                  _: always(undefined),
                },
                formField.options
              )}
            >
              <OptionContent>
                <Title>
                  <FormattedMessage id={translate.title} />
                </Title>
                <SubTitle>
                  <FormattedMessage
                    id={translate.subtitle}
                    values={{
                      br: <br />,
                    }}
                  />
                </SubTitle>
              </OptionContent>
            </Option>
          </Item>
        );
      })}

      {isInvalid && (
        <DhlValidationFeedback
          dataTestid={`shipmentProduct-${product}-parcel-validationMessage`}
          validation={{
            type: "invalid",
            message: intl.formatMessage({
              id: "FS2.Validations.shipmentProductvalueMissing",
            }),
          }}
        />
      )}
    </Container>
  );
};

ParcelOptions.propTypes = {
  className: PropTypes.string,
  product: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formField: PropTypes.object,
  isInvalid: PropTypes.bool,
};
