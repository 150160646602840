import React from "react";
import { DhlDropdown, DhlText } from "@dhl-official/react-library";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { differenceInDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import styled from "styled-components";
import {
  LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS,
  STYLES_VIEWPORTS,
} from "../../../utils/constants";
import { getIANATimeZoneName } from "../../../utils/time-zone";

// #region Component Styles
const Container = styled.div`
  align-items: flex-start;
  border-top: solid 1px var(--dui-color-gray-300);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--dui-size-space-7x);
  margin-top: var(--dui-size-space-7x);
  padding-bottom: var(--dui-size-space-7x);
  padding-top: calc(var(--dui-size-space-7x) * 2);

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const SchedulerNotice = styled(DhlText)``;

const SchedulerTitle = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

const SchedulerTimes = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: var(--dui-size-space-10x);
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    padding-left: calc(var(--dui-size-space-10x) / 2);
    width: 50%;
  }
`;

const SchedulerDay = styled(DhlDropdown)`
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex: 1;
  }
`;

const SchedulerHour = styled(SchedulerDay)`
  margin-top: var(--dui-size-space-7x);

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex: 1;
    margin-left: var(--dui-size-space-7x);
    margin-top: 0;
  }
`;

const SchedulerError = styled(DhlText).attrs({
  isParagraph: true,
})`
  width: 100%;

  & p {
    color: var(--dui-color-red-500);
    padding-top: var(--dui-size-space-7x);
  }
`;
// #endregion

const getCallbackRemoteSubmitErrorMessage = (submitError) =>
  submitError === LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS.SLOT_INVALID
    ? "FS2.Connectors.EXP_callbackRemote_schedulerSlotInvalid"
    : "FS2.Connectors.EXP_callbackRemote_schedulerSlotTaken";

const CallbackRemoteScheduler = ({
  businessUnit,
  intl,
  submitError,
  options,
  selectedDay,
  selectedSlot,
  setSchedulerDay,
  setSchedulerSlot,
}) => {
  const onDayChange = (event) => {
    setSchedulerDay(event.target.value);
  };

  const onSlotChange = (event) => {
    setSchedulerSlot(event.target.value);
  };

  const now = new Date();

  const schedulerDayOptions = Object.keys(options).map((day) => {
    const date = new Date(`${day}T23:59:59`);
    const daysDifference = differenceInDays(date, now);
    const label = [0, 1].includes(daysDifference)
      ? intl.formatMessage({
          id:
            daysDifference === 0
              ? "FS2.Connectors.EXP_callbackRemote_schedulerToday"
              : "FS2.Connectors.EXP_callbackRemote_schedulerTomorrow",
        })
      : intl.formatDate(date, {
          day: "2-digit",
          month: "long",
          weekday: "short",
        });

    return { label, value: day.toString() };
  });

  const schedulerHourOptions = options[selectedDay].map((slot) => {
    return {
      label: intl.formatTime(`1970-01-01T${slot}`),
      value: slot,
    };
  });

  return (
    <Container>
      <SchedulerNotice>
        <SchedulerTitle>
          <FormattedMessage
            id={`FS2.Connectors.${businessUnit}_callbackRemote_schedulerNotice`}
          />
        </SchedulerTitle>
        <span data-testid="callback-remote-scheduler-timezone-indicator">
          <FormattedMessage
            id={`FS2.Connectors.${businessUnit}_callbackRemote_schedulerTimezone`}
            values={{
              timezone: `${formatInTimeZone(
                new Date(),
                getIANATimeZoneName(),
                "zzz"
              )} / ${getIANATimeZoneName()}`,
            }}
          />
        </span>
      </SchedulerNotice>
      <SchedulerTimes>
        <SchedulerDay
          onDhlChange={onDayChange}
          data={schedulerDayOptions}
          dataAriaLabel={intl.formatMessage({
            id: `FS2.Connectors.${businessUnit}_callbackRemote_formLabelSchedulerDay`,
          })}
          dataId="select-day"
          dataTestid="select-day"
          name="select-day"
          value={selectedDay}
        />
        <SchedulerHour
          onDhlChange={onSlotChange}
          data={schedulerHourOptions}
          dataAriaLabel={intl.formatMessage({
            id: `FS2.Connectors.${businessUnit}_callbackRemote_formLabelSchedulerSlot`,
          })}
          dataId="select-slot"
          dataTestid="select-slot"
          name="select-slot"
          value={selectedSlot}
        />
        {submitError && (
          <SchedulerError dataTestid="callback-remote-error-notice">
            <FormattedMessage
              id={getCallbackRemoteSubmitErrorMessage(submitError)}
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
                slot: `${intl.formatDate(selectedDay, {
                  day: "2-digit",
                  month: "long",
                  weekday: "short",
                })} ${intl.formatTime(`1970-01-01T${selectedSlot}`)}`,
              }}
            />
          </SchedulerError>
        )}
      </SchedulerTimes>
    </Container>
  );
};

CallbackRemoteScheduler.propTypes = {
  businessUnit: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  submitError: PropTypes.string,
  options: PropTypes.object,
  selectedDay: PropTypes.string.isRequired,
  selectedSlot: PropTypes.string.isRequired,
  setSchedulerDay: PropTypes.func.isRequired,
  setSchedulerSlot: PropTypes.func.isRequired,
};

export default CallbackRemoteScheduler;
