import React, { memo } from "react";
import PropTypes from "prop-types";
import { DhlValidationFeedback } from "@dhl-official/react-library";
import { Container, Frequency } from "./FrequencySelector.styles";

const Component = ({
  intl,
  options,
  product,
  selectedFrequency,
  setSelectedFrequency,
  trackingPath,
  isInvalid,
  validationFeedback,
}) => {
  return (
    <div>
      <Container className="button-group">
        {options.map((f) => (
          <Frequency
            data-testid={`shipmentProduct_${product}_frequency_${f.key}`}
            data-testselected={f.key === selectedFrequency ? "true" : "false"}
            data-tracking={`${trackingPath}.frequency`}
            key={f.key}
            className="group-button"
            $isInvalid={isInvalid}
            $isSelected={f.key === selectedFrequency}
            onClick={() => setSelectedFrequency(f.key)}
            title={f.label}
          >
            {f.label}
          </Frequency>
        ))}
      </Container>
      {isInvalid && (
        <DhlValidationFeedback
          dataTestid={`shipmentProduct-${product}-frequency-validationMessage`}
          validation={{
            type: "invalid",
            message: intl.formatMessage({
              id: validationFeedback.feedbackMessageId,
            }),
          }}
        />
      )}
    </div>
  );
};

Component.propTypes = {
  intl: PropTypes.object.isRequired,
  isInvalid: PropTypes.bool,
  options: PropTypes.array.isRequired,
  product: PropTypes.string.isRequired,
  selectedFrequency: PropTypes.string,
  setSelectedFrequency: PropTypes.func.isRequired,
  trackingPath: PropTypes.string.isRequired,
  validationFeedback: PropTypes.object,
};

export const FrequencySelector = memo(Component);
