import React from "react";
import { compose, map, path, filter } from "ramda";
import FF from "../../../utils/functional/form-field";
import { PARCEL_OPTIONS } from "../../../utils/constants";
import {
  CHECKBOX_GROUP_NAME,
  TRACKING_KEY,
  PARCEL,
  SHIPMENT_PRODUCT_ICONS,
} from "./ProductDetails.contants";
import ParcelOptions from "./ParcelOptions/ParcelOptions.container";

export const getParcelOption = (options) => {
  const optionsValue = FF.getValue(options);
  if (!optionsValue) return undefined;

  if (optionsValue.standard && !optionsValue.expedited) {
    return PARCEL_OPTIONS.STANDARD;
  }
  if (!optionsValue.standard && optionsValue.expedited) {
    return PARCEL_OPTIONS.EXPEDITED;
  }
  return undefined;
};

export const getFrequencyOptions = (intl, frequencies) =>
  frequencies.map((option) => ({
    ...option,
    label: intl.formatMessage({
      id: `FS2.CardShipmentProduct.frequencyOptionLabel_${option.key}`,
    }),
  }));

export const getProductFieldUtils = (productFields) => {
  const getShipmentScaleOptions = (product) =>
    compose(
      FF.getValue,
      path([product, "regions"]),
      FF.getValue
    )(productFields);

  return {
    getRegionOptions: (product) =>
      Object.keys(getShipmentScaleOptions(product)),

    getSelectedRegions: (product) =>
      compose(
        map(([region]) => region),
        filter(([, checked]) => checked),
        Object.entries
      )(getShipmentScaleOptions(product)),

    getRegionValidation: (product) =>
      compose(
        FF.isInvalid,
        path([product, "regions"]),
        FF.getValue
      )(productFields),

    getOptionValidation: (product) =>
      compose(
        FF.isInvalid,
        path([product, "options"]),
        FF.getValue
      )(productFields),
  };
};

export const getExtraOptionsComponent = (id, formField, productFields) => {
  if (!formField.options) {
    return undefined;
  }

  switch (id) {
    case PARCEL: {
      const utils = getProductFieldUtils(productFields);
      return (
        <ParcelOptions
          formField={formField}
          product={id}
          isInvalid={utils.getOptionValidation(id)}
        />
      );
    }
    default:
      return undefined;
  }
};

export const createCardProps = (product, formField, utils, options) => {
  const {
    intl,
    frequencyOptions,
    profile,
    shouldShowOptions,
    productFields,
    noMatchesMap,
  } = options;

  const productLabel = intl.formatMessage({
    id: `FS2.CardShipmentProduct.shipmentProduct_${product}_Label`,
  });

  const productInfo = intl
    .formatMessage({ id: `FS2.Product_${product}_Instructions` })
    .split("|||")
    .slice(0, 2);

  return {
    extraOptions: shouldShowOptions
      ? getExtraOptionsComponent(product, formField, productFields)
      : undefined,
    frequency: formField.frequency,
    frequencyOptions,
    name: CHECKBOX_GROUP_NAME,
    noMatches: noMatchesMap[product],
    numberOfShipments: formField.numberOfShipments,
    parcelOption: getParcelOption(formField.options),
    product,
    productIcon: SHIPMENT_PRODUCT_ICONS[product],
    productInfo,
    productLabel,
    profile,
    isRegionInvalid: utils.getRegionValidation(product),
    regionOptions: utils.getRegionOptions(product),
    regions: formField.regions,
    selectedRegions: utils.getSelectedRegions(product),
    trackingPath: `${TRACKING_KEY}.${product.toLowerCase()}`,
  };
};
