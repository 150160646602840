import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import { ParcelOptions } from "./ParcelOptions";
import * as actions from "../../../../reducers/actions";

const mapDispatchToProps = {
  onChange: actions.setProductUrgency,
};

export default compose(
  memo,
  connect(undefined, mapDispatchToProps)
)(ParcelOptions);
