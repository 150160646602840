import { connect } from "react-redux";
import { compose } from "ramda";
import { memo } from "react";
import { injectIntl } from "react-intl";
import * as reducers from "../../../../reducers";
import * as actions from "../../../../reducers/actions";
import CallbackForm from ".";
import RD from "../../../../utils/functional/remote-data";

const mapStateToProps = (state) => ({
  activeStep: reducers.getActiveStep(state),
  isCallbackRemoteSlotsLoading: RD.isLoading(
    reducers.getCallbackRemoteSlots(state)
  ),
  callbackRemoteDisplay: reducers.getCallbackRemoteDisplay(state),
  isNextStepEnabled: reducers.isNextStepEnabled(state),
  isRtlLayout: reducers.isRtlLayout(state),
  isWaitingForEndpoint: reducers.getIsLoadingGotLead(state),
  profile: reducers.getProfile(state),
  schedulerDisplay: reducers.getSchedulerDisplay(state),
  theme: reducers.getTheme(state),
});

const mapDispatchToProps = {
  goToNextStep: actions.goToNextStepEffect,
};

export default compose(
  memo,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(CallbackForm);
