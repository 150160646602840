import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { DhlCard, DhlCheckbox, DhlText } from "@dhl-official/react-library";
import { compose } from "ramda";
import RD from "../../utils/functional/remote-data";
import CallbackRemoteScheduler from "./CallbackRemoteScheduler/container";
import CountryAutoCompleteField from "./CountryAutoCompleteField/container";
import CustomInputField from "./CustomInputField/container";
import CustomTextAreaField from "./CustomTextAreaField/container";
import CountryCallingCodeField from "./CountryCallingCodeField/container";
import Scheduler from "./Scheduler/container";
import ViewHeadline from "../ViewHeadline/container";
import { STYLES_VIEWPORTS } from "../../utils/constants";
import { useContentPrefix } from "../../hooks/useContentPrefix";
import ButtonLoader from "../ButtonLoader";
import FF from "../../utils/functional/form-field";

const COPY_ID_PREFIX = "FS2.ContactForm";
const TEXT_AREA_HEIGHT = "235px";

// #region Component Styles
const Container = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.LARGE}) {
    padding: 0 var(--dui-size-space-10x);
  }
`;

const Legend = styled.legend`
  width: 100%;
  text-align: center;
  padding-bottom: var(--dui-size-space-10x);
  padding-left: var(--dui-size-space-7x);
  padding-right: var(--dui-size-space-7x);
  padding-top: var(--dui-size-space-7x);
  display: block;
`;

const RequiredInstruction = styled.div.attrs({
  "data-testid": "required-instructions",
})`
  color: var(--dui-color-gray-600);
  margin-bottom: var(--dui-size-space-7x);
  margin-top: 0;
  text-align: left;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    margin-top: calc(var(--dui-size-space-7x) * 2);
  }
`;

const FormWrapper = styled(DhlCard)`
  & .card-container {
    margin-top: ${({ $isBorderLess }) =>
      $isBorderLess ? 0 : "var(--dui-size-space-10x)"};
    overflow: visible;
  }

  & .card-body {
    padding: ${({ $isBorderLess }) =>
      $isBorderLess ? 0 : "var(--dui-size-space-10x)"};

    @media screen and (min-width: ${STYLES_VIEWPORTS.LARGE}) {
      padding: var(--dui-size-space-10x);
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormColumn = styled.div`
  display: block;
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    padding-right: calc(var(--dui-size-space-10x) / 2);
    width: 50%;
  }

  &:last-of-type {
    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      padding-left: calc(var(--dui-size-space-10x) / 2);
      padding-right: 0;
    }
  }
`;

const InputField = styled(CustomInputField)`
  & > div {
    margin-bottom: var(--dui-size-space-7x);
    width: 100%;
  }

  & input {
    -webkit-appearance: none;
  }
`;

const CountryField = styled(InputField).attrs({
  as: CountryAutoCompleteField,
})``;

const TextArea = styled(InputField).attrs({ as: CustomTextAreaField })`
  & textarea {
    height: ${TEXT_AREA_HEIGHT};
    resize: vertical;
    -webkit-appearance: none;
  }
`;

const TwoInputRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row;

    ${InputField} {
      flex-grow: 1;
    }

    ${InputField}:first-child {
      margin-right: var(--dui-size-space-7x);
    }
  }
`;

const PhoneNumberContainerRowsMobile = () => css`
  display: block;
`;

const PhoneNumberContainer = styled.div`
  /*! @noflip */
  direction: ltr;
  align-items: flex-start;
  display: flex;

  ${({ $withPhoneExtension }) =>
    $withPhoneExtension && PhoneNumberContainerRowsMobile()};

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    align-items: flex-start;
    display: flex;
  }
`;

const CountryCallingCodeContainer = styled.div`
  display: block;
  margin-bottom: var(--dui-size-space-7x);
  flex: 0 0 0;
  flex-basis: 90px;
  /*! @noflip */
  margin-right: ${({ $withPhoneExtension }) =>
    $withPhoneExtension ? 0 : "var(--dui-size-space-3x)"};

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    /*! @noflip */
    margin-right: var(--dui-size-space-3x);
  }
`;

const StyledPhoneField = styled(InputField)`
  direction: ltr;
  flex-grow: 1;
`;

const StyledPhoneExtensionField = styled(InputField)`
  direction: ltr;
  display: block;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex: 0 0 0;
    flex-basis: 75px;
    /*! @noflip */
    margin-left: var(--dui-size-space-3x);
  }

  @media screen and (min-width: ${STYLES_VIEWPORTS.LARGE}) {
    flex-basis: 90px;
  }
`;

const PrivacyNotice = styled(DhlText).attrs({
  dataTestid: "privacy-notice",
  isParagraph: true,
  size: "sm",
})`
  & p {
    margin: var(--dui-size-space-10x) auto var(--dui-size-space-7x) auto;
    max-width: 420px;
    text-align: center;
  }
`;

const MarketingOptInContainer = styled.div`
  padding: 1rem 0 3rem 0;
`;

const MarketingOptIn = styled(DhlCheckbox).attrs({
  size: "sm",
})`
  & > div {
    display: inline-block;
    position: relative;
  }

  & > div > label.sm {
    height: auto;
  }

  & input {
    height: var(--dui-size-space-12x);
    width: var(--dui-size-space-12x);
    top: 1px;
  }
`;

const MarketingText = styled(DhlText).attrs({
  isParagraph: true,
})``;

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--dui-size-space-7x) 0;
`;

// #endregion

const ContactForm = ({
  callbackRemoteDisplay,
  isCallbackRemoteSlotsLoading,
  connectors,
  contactInformation,
  intl,
  isBorderLess,
  onContactInformationChange,
  onValidateContactInformation,
  onValidateCountry,
  schedulerDisplay,
  title,
  withPhoneExtension,
  withMarketingOptIn,
}) => {
  const {
    address,
    city,
    comments,
    company,
    country,
    countryCallingCode,
    email,
    firstName,
    lastName,
    phoneExtension,
    phoneNumber,
    zip,
    marketingOptIn,
  } = contactInformation;

  const handleContactInformationChange = useCallback((event) => {
    // TODO: use name instead id, after DUIL bugfix
    const { id: idTarget, name: nameTarget, value } = event.target;
    const name = nameTarget || idTarget;
    onContactInformationChange({ name, value });
  }, []);

  const handleCheckboxChange = useCallback((event) => {
    const { id: idTarget, name: nameTarget, checked: value } = event.target;
    const name = nameTarget || idTarget;
    onContactInformationChange({ name, value });
  }, []);

  const marketingOptInField = FF.getMeta(marketingOptIn).id;

  const getCopy = (copyId) =>
    intl.formatMessage({ id: `${COPY_ID_PREFIX}.${copyId}` }, { nl: "\n" });

  const getOptionalCopy = (copyId) =>
    intl.messages[`${COPY_ID_PREFIX}.${copyId}`] ? getCopy(copyId) : undefined;

  const labels = {
    address: getCopy("formLabelAddress"),
    city: getCopy("formLabelCity"),
    comments: getCopy("formLabelComments"),
    companyName: getCopy("formLabelCompanyName"),
    country: getCopy("formLabelCountry"),
    countryCallingCode: getCopy("formLabelCountryCallingCode"),
    email: getCopy("formLabelEmail"),
    firstName: getCopy("formLabelFirstName"),
    lastName: getCopy("formLabelLastName"),
    phoneExtension: getCopy("formLabelPhoneExtension"),
    phoneNumber: getCopy("formLabelPhoneNumber"),
    zip: getCopy("formLabelZip"),
    marketingOptIn: getCopy("formLabelMarketingOptIn"),
  };

  const placeholders = {
    comments: getOptionalCopy("formPlaceholderComments"),
    email: getCopy("formPlaceholderEmail"),
    phoneNumber: getCopy("formPlaceholderPhoneNumber"),
  };

  const contentPrefix = useContentPrefix();

  // FIXME: tempPrefix To be removed once all localizaition
  const tempPrefix = contentPrefix === "FSDEC" ? "FSDEC" : "FS2";

  return (
    <Container>
      <Legend>
        {title || (
          <ViewHeadline dataTestid="contact-form-title">
            <FormattedMessage id={`${tempPrefix}.ContactForm.textTop`} />
          </ViewHeadline>
        )}
      </Legend>

      <FormWrapper $isBorderLess={isBorderLess} as={isBorderLess && "div"}>
        <RequiredInstruction>
          <DhlText size="sm" dataTestid="required-instructions-text">
            <FormattedMessage id="FS2.ContactForm.requiredMessage" />
          </DhlText>
        </RequiredInstruction>

        <FormContainer>
          <FormColumn>
            <TwoInputRowContainer>
              <InputField
                field={firstName}
                label={labels.firstName}
                onChange={handleContactInformationChange}
                validateFormFields={onValidateContactInformation}
              />

              <InputField
                field={lastName}
                label={labels.lastName}
                onChange={handleContactInformationChange}
                validateFormFields={onValidateContactInformation}
              />
            </TwoInputRowContainer>

            <InputField
              field={company}
              label={labels.companyName}
              onChange={handleContactInformationChange}
              validateFormFields={onValidateContactInformation}
            />

            <InputField
              field={address}
              label={labels.address}
              onChange={handleContactInformationChange}
              validateFormFields={onValidateContactInformation}
            />

            {compose(
              RD.withDefault(null),
              RD.map((field) => (
                // eslint-disable-next-line react/jsx-key
                <InputField
                  field={field}
                  label={labels.zip}
                  onChange={handleContactInformationChange}
                  validateFormFields={onValidateContactInformation}
                />
              ))
            )(zip)}

            <InputField
              field={city}
              label={labels.city}
              onChange={handleContactInformationChange}
              validateFormFields={onValidateContactInformation}
            />

            <CountryField
              field={country}
              label={labels.country}
              validateFormFields={onValidateCountry}
            />
          </FormColumn>

          <FormColumn>
            <InputField
              field={email}
              label={labels.email}
              onChange={handleContactInformationChange}
              placeholder={placeholders.email}
              validateFormFields={onValidateContactInformation}
            />

            <PhoneNumberContainer $withPhoneExtension={withPhoneExtension}>
              <CountryCallingCodeContainer
                $withPhoneExtension={withPhoneExtension}
              >
                <CountryCallingCodeField
                  field={countryCallingCode}
                  label={labels.countryCallingCode}
                  onChange={handleContactInformationChange}
                />
              </CountryCallingCodeContainer>

              {compose(
                RD.withDefault(null),
                RD.map((field) => (
                  // eslint-disable-next-line react/jsx-key
                  <StyledPhoneField
                    field={field}
                    label={labels.phoneNumber}
                    onChange={handleContactInformationChange}
                    placeholder={placeholders.phoneNumber}
                    validateFormFields={onValidateContactInformation}
                  />
                ))
              )(phoneNumber)}

              {withPhoneExtension && (
                <StyledPhoneExtensionField
                  field={phoneExtension}
                  label={labels.phoneExtension}
                  onChange={handleContactInformationChange}
                  validateFormFields={onValidateContactInformation}
                />
              )}
            </PhoneNumberContainer>

            <TextArea
              field={comments}
              label={labels.comments}
              onChange={handleContactInformationChange}
              placeholder={placeholders.comments}
              validateFormFields={onValidateContactInformation}
            />
          </FormColumn>

          {withMarketingOptIn && (
            <MarketingOptInContainer>
              <MarketingOptIn
                dataTestid={marketingOptInField}
                name={marketingOptInField}
                value={FF.getValue(marketingOptIn)}
                checked={FF.getValue(marketingOptIn)}
                onDhlChange={handleCheckboxChange}
              >
                <MarketingText>{labels.marketingOptIn}</MarketingText>
              </MarketingOptIn>
            </MarketingOptInContainer>
          )}
        </FormContainer>

        {isCallbackRemoteSlotsLoading &&
          !schedulerDisplay &&
          !callbackRemoteDisplay && (
            <LoaderWrapper>
              <ButtonLoader />
            </LoaderWrapper>
          )}

        {schedulerDisplay && connectors?.id && (
          <Scheduler businessUnit={connectors?.id} />
        )}

        {callbackRemoteDisplay && connectors?.id && (
          <CallbackRemoteScheduler businessUnit={connectors.id} />
        )}

        <PrivacyNotice>
          <FormattedMessage id="FS2.ContactForm.textBottom" />
        </PrivacyNotice>
      </FormWrapper>
    </Container>
  );
};

ContactForm.propTypes = {
  callbackRemoteDisplay: PropTypes.bool.isRequired,
  isCallbackRemoteSlotsLoading: PropTypes.bool.isRequired,
  connectors: PropTypes.object,
  contactInformation: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isBorderLess: PropTypes.bool,
  onContactInformationChange: PropTypes.func.isRequired,
  onValidateContactInformation: PropTypes.func.isRequired,
  onValidateCountry: PropTypes.func.isRequired,
  schedulerDisplay: PropTypes.bool.isRequired,
  title: PropTypes.node,
  withPhoneExtension: PropTypes.bool.isRequired,
  withMarketingOptIn: PropTypes.bool.isRequired,
};

export default ContactForm;
