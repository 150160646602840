import React from "react";
import PropTypes from "prop-types";

import FF from "../../../utils/functional/form-field";
import ProductDetailsCard from "./ProductDetailsCard/ProductDetailsCard.container";
import CardGroup from "../../Cards/Group";
import { FREQUENCY_OPTIONS, PROFILES, STEPS } from "../../../utils/constants";
import { isFSLikeProfile } from "../../../utils";
import { CardContainer } from "./ProductDetails.styles";
import {
  CHECKBOX_GROUP_NAME,
  FREIGHT,
  MAIL,
  PARCEL,
} from "./ProductDetails.contants";

import {
  getFrequencyOptions,
  getProductFieldUtils,
  createCardProps,
} from "./ProductDetails.helpers";

const ProductDetails = ({
  activeStep,
  contentPrefix,
  freightNoMatches,
  intl,
  mailNoMatches,
  options,
  parcelNoMatches,
  productFields,
  profile,
  shouldShowOptions,
  abTesting,
  openLinksInSameTab,
  reroutingRegionData,
  reroutingThresholdData,
}) => {
  const getMessage = (id) => intl.formatMessage({ id });
  const frequencyOptions = getFrequencyOptions(intl, FREQUENCY_OPTIONS);

  const noMatchesMap = {
    [PARCEL]: parcelNoMatches,
    [FREIGHT]: freightNoMatches,
    [MAIL]: mailNoMatches,
  };

  const utils = getProductFieldUtils(productFields);

  const getStepHeadlineId = () =>
    isFSLikeProfile(profile)
      ? "FS2.RegularShipment.step2Headline"
      : `${contentPrefix}.RegularShipment.stepProductDetailsHeadline`;

  const checkedProducts = options.filter(
    (product) => FF.getValue(productFields)[product].checked
  );

  const maxTitleLength = checkedProducts.reduce((acc, product) => {
    const productLabel = intl.formatMessage({
      id: `FS2.CardShipmentProduct.shipmentProduct_${product}_Label`,
    });
    return Math.max(acc, productLabel.length);
  }, 0);

  return (
    <CardGroup
      isResponsive
      wrapContent
      activeStep={activeStep}
      feedback={FF.getFeedback(productFields)}
      idForLabeledBy={`${CHECKBOX_GROUP_NAME}-labelId`}
      legendCopy={getMessage(getStepHeadlineId())}
    >
      {() =>
        checkedProducts.map((product) => {
          const key = `${CHECKBOX_GROUP_NAME}_${product}`;
          const formField = FF.getValue(productFields)[product];

          const cardProps = createCardProps(product, formField, utils, {
            intl,
            frequencyOptions,
            profile,
            shouldShowOptions,
            productFields,
            noMatchesMap,
          });

          return (
            <CardContainer key={key}>
              <ProductDetailsCard
                abTesting={abTesting}
                openLinksInSameTab={openLinksInSameTab}
                reroutingRegionData={reroutingRegionData}
                reroutingThresholdData={reroutingThresholdData}
                extraOptions={cardProps.extraOptions}
                frequency={cardProps.frequency}
                frequencyOptions={cardProps.frequencyOptions}
                name={cardProps.name}
                noMatches={cardProps.noMatches}
                numberOfShipments={cardProps.numberOfShipments}
                parcelOption={cardProps.parcelOption}
                maxTitleLength={maxTitleLength}
                product={cardProps.product}
                productIcon={cardProps.productIcon}
                productInfo={cardProps.productInfo}
                productLabel={cardProps.productLabel}
                profile={cardProps.profile}
                isRegionInvalid={cardProps.isRegionInvalid}
                regionOptions={cardProps.regionOptions}
                regions={cardProps.regions}
                selectedRegions={cardProps.selectedRegions}
                trackingPath={cardProps.trackingPath}
              />
            </CardContainer>
          );
        })
      }
    </CardGroup>
  );
};

ProductDetails.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  contentPrefix: PropTypes.string.isRequired,
  freightNoMatches: PropTypes.object,
  intl: PropTypes.object.isRequired,
  mailNoMatches: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  parcelNoMatches: PropTypes.object,
  productFields: PropTypes.object.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  shouldShowOptions: PropTypes.bool.isRequired,
  abTesting: PropTypes.string,
  openLinksInSameTab: PropTypes.bool,
  reroutingRegionData: PropTypes.object,
  reroutingThresholdData: PropTypes.object,
};

export default ProductDetails;
