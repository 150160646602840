export const calculateTitleMinHeight = (
  maxTitleLength,
  baseFontSize,
  lineHeight
) => {
  const lines = Math.floor(maxTitleLength / 25) + 1;
  return `calc(${lines} * ${baseFontSize} * ${lineHeight})`;
};

export const isTitleTooLong = (maxTitleLength) => {
  return maxTitleLength > 25;
};
