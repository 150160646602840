import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DhlCard, DhlCheckbox } from "@dhl-official/react-library";
import { STYLES_VIEWPORTS_VALUES } from "../../../utils/constants";

const ProductIcon = styled.svg.attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  background-color: var(--dui-color-gray-50);
  height: 96px;
  padding: 10px;
  width: 96px;
  @media screen and (max-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM - 1}px) {
    height: 56px;
    width: 56px;
  }
`;

// #region Component Styles
const Container = styled(DhlCard)`
  display: flex;
  height: 100%;
  flex: 1;

  & .card-container {
    height: 100%;
    width: 100%;
  }

  & .card-body {
    border-style: solid;
    border-width: 1px;
    border-color: ${({ $hasError }) =>
      $hasError ? `var(--dui-color-red-500)` : "transparent"};
    border-radius: var(--dui-size-radius-md);
    height: 100%;
    padding-bottom: var(--dui-size-space-8x);
    padding-left: var(--dui-size-space-8x);
    padding-right: var(--dui-size-space-8x);
    padding-top: var(--dui-size-space-8x);
    position: relative;
  }

  &:focus-within .card-body {
    border-color: var(--dui-color-black-400);
    box-shadow: 0 0 0 2px var(--dui-color-black-400);
  }

  @media screen and (max-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM - 1}px) {
    width: 100%;
    max-width: 100%;
    min-width: 250px;
    & .card-container {
      width: 100%;
    }
  }
`;

const Checkbox = styled(DhlCheckbox)`
  position: absolute;
  top: var(--dui-size-space-8x);
  right: var(--dui-size-space-3x);
  input {
    cursor: pointer;
    top: 0;
    width: var(--dui-size-space-16x);
    height: var(--dui-size-space-16x);
  }
`;

const Label = styled.label`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardSelectable = ({
  dataAriaLabel,
  children,
  dataTestid,
  dataTrackingKeyPath,
  feedback,
  isChecked,
  name,
  onChange,
  reference,
  productIcon,
  value,
}) => {
  const hasError = feedback && feedback.hasError;
  return (
    <Container
      data-testid={dataTestid}
      $hasError={hasError}
      ref={reference}
      className="selectable-wrapper"
    >
      <Label htmlFor={value}>
        <HeaderWrapper>
          <ProductIcon as={productIcon} />
          <Checkbox
            ariaInvalid={hasError}
            dataAriaLabel={dataAriaLabel}
            dataTestid={value}
            dataTracking={dataTrackingKeyPath}
            id={value}
            checked={isChecked}
            required={false}
            validation={
              hasError
                ? {
                    type: "invalid",
                    message: "",
                  }
                : {
                    type: "none",
                    message: "",
                  }
            }
            name={name}
            onClick={onChange}
            onDhlChange={onChange}
            value={value}
          />
        </HeaderWrapper>
        {children}
      </Label>
    </Container>
  );
};

CardSelectable.propTypes = {
  dataAriaLabel: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  dataTestid: PropTypes.string,
  dataTrackingKeyPath: PropTypes.string,
  feedback: PropTypes.object,
  isChecked: PropTypes.bool.isRequired,
  productIcon: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  reference: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default memo(CardSelectable);
