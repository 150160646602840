import {
  IlluFreightShipping,
  IlluParcelDocuments,
  IlluTwoDocuments,
} from "@dhl-official/icons";

export const CHECKBOX_GROUP_NAME = "productFields";
export const TRACKING_KEY =
  "digitalLayer.gaq.regularShipmentLP.requestData.shipmentProduct";

export const FREIGHT = "FREIGHT";
export const MAIL = "MAIL";
export const PARCEL = "PARCEL";

export const SHIPMENT_PRODUCT_ICONS = {
  [FREIGHT]: IlluFreightShipping,
  [MAIL]: IlluTwoDocuments,
  [PARCEL]: IlluParcelDocuments,
};
