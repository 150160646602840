import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { DhlText, DhlValidationFeedback } from "@dhl-official/react-library";
import ViewHeadline from "../../ViewHeadline/container";
import {
  STEPS,
  STYLES_VIEWPORTS_VALUES,
  MAX_CONTENT_WIDTH,
} from "../../../utils/constants";

// #region Component Styles
const Container = styled.fieldset`
  border: 0;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const Legend = styled.legend`
  display: block;
  padding-bottom: var(--dui-size-space-10x);
  padding-top: var(--dui-size-space-7x);
  text-align: center;
  width: 100%;
`;

const SubTitle = styled(DhlText).attrs({
  isParagraph: true,
})`
  margin-bottom: var(--dui-size-space-3x);
  margin-top: 0;
`;

const CardContainer = styled.ul`
  display: flex;
  flex-wrap: ${({ $wrapContent }) => ($wrapContent ? "wrap" : "nowrap")};
  justify-content: center;
  line-height: initial;
  margin-bottom: 0;
  padding-bottom: var(--dui-size-space-3x);
  padding-top: var(--dui-size-space-7x);
  text-align: left;
  ${({ $isResponsive }) => {
    return $isResponsive
      ? `
      margin: 0 auto;
      max-width: ${MAX_CONTENT_WIDTH}px;
    @media screen and (max-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM - 1}px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `
      : "";
  }}
`;

const Feedback = styled(DhlValidationFeedback)`
  display: inline-flex;
  margin-bottom: var(--dui-size-space-7x);
`;
// #endregion

const CardGroup = ({
  activeStep,
  children,
  className,
  feedback,
  idForLabeledBy,
  intl,
  legendCopy,
  subLegendCopy,
  isResponsive,
  wrapContent = true,
}) => {
  const hasError = feedback && feedback.hasError && feedback.feedbackMessageId;
  const ariaDescribedBy = hasError ? `errorMessage_${idForLabeledBy}` : "";

  return (
    <Container
      aria-describedby={`${idForLabeledBy}-fieldsetDescription`}
      className={className}
    >
      <Legend>
        <ViewHeadline id={idForLabeledBy} dataTestid={`${activeStep}-headline`}>
          {legendCopy}
        </ViewHeadline>
      </Legend>

      {subLegendCopy && (
        <SubTitle id={`${idForLabeledBy}-fieldsetDescription`}>
          {subLegendCopy}
        </SubTitle>
      )}

      <CardContainer $isResponsive={isResponsive} $wrapContent={wrapContent}>
        {children(ariaDescribedBy)}
      </CardContainer>

      {hasError && (
        <Feedback
          id={`errorMessage_${idForLabeledBy}`}
          dataTestid={`errorMessage_${idForLabeledBy}`}
          validation={{
            message: intl.formatMessage({ id: feedback.feedbackMessageId }),
            type: "invalid",
          }}
        />
      )}
    </Container>
  );
};

CardGroup.propTypes = {
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  feedback: PropTypes.object,
  idForLabeledBy: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  legendCopy: PropTypes.string.isRequired,
  subLegendCopy: PropTypes.node,
  isResponsive: PropTypes.bool,
  wrapContent: PropTypes.bool,
};

export default memo(injectIntl(CardGroup));
