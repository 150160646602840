import { DhlLink } from "@dhl-official/react-library";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { trackContentInteraction } from "../../../../utils/track-utf";
import {
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_POSITIONS,
  UTF_EVENT_NAMES,
  CONTENT_PREFIXES,
} from "../../../../utils/constants";
import { createAbsoluteUrl } from "../../../../utils/absolute-url";
import { getLinkTarget } from "../../../../utils/link-target";
import {
  REROUTING_DATA_TESTIDS,
  TRACKING_KEYS,
} from "./ProductDetailsCard.constants";
import { Small } from "./ProductDetailsCard.styles";

export const triggerLinkTrackingEvent = (context, name, position) => {
  trackContentInteraction({
    context,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position,
    attributes: {
      step: UTF_EVENT_POSITIONS.PRODUCT_DETAILS,
    },
  });
};

export const getHeadline = (label, options) =>
  options.length !== 1 ? (
    label
  ) : (
    <Fragment>
      {label}
      <Small>
        <FormattedMessage
          id={`FS2.CardShipmentScale.shipmentScale_${options[0]}_Only`}
        />
      </Small>
    </Fragment>
  );

export const getReroutingRegionMessage = (
  reroutingRegionData,
  product,
  missingRegion,
  availableRegion
) =>
  reroutingRegionData?.[product]?.[missingRegion]
    ? `FS2.Rerouting.RegionProductDetailsWithLink_${availableRegion}`
    : `FS2.Rerouting.RegionProductDetailsWithoutLink_${availableRegion}`;

export const getReroutingRegionLink = (
  reroutingRegionData,
  product,
  missingRegion,
  getMessage,
  openLinksInSameTab
) =>
  reroutingRegionData?.[product]?.[missingRegion] ? (
    <AlternativeServiceLink
      dataTestid={`${REROUTING_DATA_TESTIDS.region.link}-${product}`}
      dataTracking={`${TRACKING_KEYS.reroutingRegion}.${reroutingRegionData[product][missingRegion]}`}
      href={createAbsoluteUrl(
        getMessage(
          `FS2.Rerouting.AlternativeService_${reroutingRegionData[product][missingRegion]}_URL`
        )
      )}
      onClick={() =>
        triggerLinkTrackingEvent(
          reroutingRegionData[product][missingRegion],
          UTF_EVENT_NAMES.REGION_NOTICE,
          UTF_EVENT_POSITIONS.REROUTING_CLICK
        )
      }
      target={getLinkTarget(openLinksInSameTab)}
    >
      <FormattedMessage
        id={`FS2.Rerouting.AlternativeService_${reroutingRegionData[product][missingRegion]}_Label`}
      />
    </AlternativeServiceLink>
  ) : (
    ""
  );

export const getReroutingThresholdMessage = (
  reroutingThresholdData,
  parcelOption,
  product,
  profile
) => {
  const formPosition = parcelOption ? "ParcelOptions" : "Threshold";

  // FIXME: tempPrefix To be removed once all localizaition
  // CONTENT_PREFIXES[profile ?? "FS"]
  const tempPrefix = profile === "FSDEC" ? "FSDEC" : "FS";

  return reroutingThresholdData?.[product]
    ? `${CONTENT_PREFIXES[tempPrefix]}.Rerouting.${formPosition}ProductDetailsWithLink`
    : `FS2.Rerouting.${formPosition}ProductDetailsWithoutLink`;
};

export const AlternativeServiceLink = styled(DhlLink).attrs({
  fontSize: "sm",
  rel: "noopener noreferrer",
})``;

export const getReroutingThresholdLink = (
  reroutingThresholdData,
  product,
  getMessage,
  openLinksInSameTab
) =>
  reroutingThresholdData?.[product] ? (
    <AlternativeServiceLink
      dataTestid={`${REROUTING_DATA_TESTIDS.threshold.link}-${product}`}
      dataTracking={`${TRACKING_KEYS.reroutingThreshold}.${reroutingThresholdData[product]}`}
      href={createAbsoluteUrl(
        getMessage(
          `FS2.Rerouting.AlternativeService_${reroutingThresholdData[product]}_URL`
        )
      )}
      onClick={() =>
        triggerLinkTrackingEvent(
          reroutingThresholdData[product],
          UTF_EVENT_NAMES.THRESHOLD_NOTICE,
          UTF_EVENT_POSITIONS.REROUTING_CLICK
        )
      }
      target={getLinkTarget(openLinksInSameTab)}
    >
      <FormattedMessage
        id={`FS2.Rerouting.AlternativeService_${reroutingThresholdData[product]}_Label`}
      />
    </AlternativeServiceLink>
  ) : (
    ""
  );
