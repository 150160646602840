import { compose } from "ramda";
import { memo } from "react";
import { connect } from "react-redux";
import LoadingSkeleton from ".";

const mapDispatchToProps = {};

export default compose(
  memo,
  connect(null, mapDispatchToProps)
)(LoadingSkeleton);
