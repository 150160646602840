import { identity } from "ramda";
import { SHOW_CALL_US_NUMBER } from "./actions";

const INITIAL_STATE = {
  showCallUsNumber: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
    case SHOW_CALL_US_NUMBER:
      return { ...state, showCallUsNumber: true };

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
