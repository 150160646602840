export const getHashParam = (name) => {
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  return hashParams.get(name);
};

export const setHashParam = (name, value) => {
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  hashParams.set(name, value);
  window.location.hash = decodeURIComponent(hashParams.toString());
};
