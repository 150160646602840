import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { always, propEq } from "ramda";
import FF from "../../../../utils/functional/form-field";
import {
  AB_TESTING_CAMPAIGNS,
  PROFILES,
  REGIONS,
  UTF_EVENT_NAMES,
  UTF_EVENT_POSITIONS,
} from "../../../../utils/constants";
import { FrequencySelector } from "../FrequencySelector/FrequencySelector";
import { RegionOptions } from "../RegionOptions/RegionOptions";
import { isFSLikeProfile } from "../../../../utils";
import { isTitleTooLong } from "../../helpers";
import {
  Container,
  FlexWrapper,
  ProductIcon,
  ProductLabel,
  ProductInfo,
  ProductInfoList,
  ProductInfoListItem,
  ProductInfoListText,
  HeadlineContainer,
  InputContainer,
  NumberOfShipmentsContainer,
  NumberOfShipments,
  CloseButton,
  CloseIcon,
  NoMatches,
  NoMatchesIcon,
  ReroutingRegionNotice,
  ProductText,
} from "./ProductDetailsCard.styles";
import { REROUTING_DATA_TESTIDS } from "./ProductDetailsCard.constants";
import {
  triggerLinkTrackingEvent,
  getHeadline,
  getReroutingRegionMessage,
  getReroutingRegionLink,
  getReroutingThresholdMessage,
  getReroutingThresholdLink,
} from "./ProductDetailsCard.helpers";

export const ProductDetailsCard = ({
  abTesting,
  extraOptions,
  frequency,
  frequencyOptions,
  intl,
  isRegionInvalid,
  name,
  noMatches,
  numberOfShipments,
  onRemoveProduct,
  onSetFrequency,
  onSetNumberOfShipments,
  onSetRegion,
  openLinksInSameTab,
  parcelOption,
  product,
  productIcon,
  productInfo,
  productLabel,
  profile,
  regions,
  regionOptions,
  reroutingRegionData,
  reroutingThresholdData,
  selectedRegions,
  trackingPath,
  maxTitleLength,
}) => {
  const [isNoMatchesTrue, setIsNoMatchesTrue] = useState(true);

  const showReroutingRegionForGlobal =
    regionOptions.length === 1 && regionOptions[0] === REGIONS.DOMESTIC;

  const showReroutingRegionForDomestic = !regionOptions.includes(
    REGIONS.DOMESTIC
  );

  const showReroutingRegionNotice =
    showReroutingRegionForGlobal || showReroutingRegionForDomestic;

  useEffect(() => {
    if (
      showReroutingRegionForDomestic &&
      reroutingRegionData?.[product]?.[REGIONS.DOMESTIC]
    ) {
      triggerLinkTrackingEvent(
        reroutingRegionData?.[product]?.[REGIONS.DOMESTIC],
        UTF_EVENT_NAMES.REGION_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }

    if (
      showReroutingRegionForGlobal &&
      reroutingRegionData?.[product]?.[REGIONS.GLOBAL]
    ) {
      triggerLinkTrackingEvent(
        reroutingRegionData?.[product]?.[REGIONS.GLOBAL],
        UTF_EVENT_NAMES.REGION_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
  }, [showReroutingRegionNotice]);

  useEffect(() => {
    if (noMatches && isNoMatchesTrue) {
      setIsNoMatchesTrue(false);
      triggerLinkTrackingEvent(
        reroutingThresholdData?.[product],
        UTF_EVENT_NAMES.THRESHOLD_NOTICE,
        UTF_EVENT_POSITIONS.REROUTING_DISPLAY
      );
    }
  }, [noMatches]);

  const onRegionChange = useCallback(
    (value) => {
      onSetRegion([product, value]);
    },
    [product, onSetRegion]
  );

  const onFrequencyChange = useCallback(
    (value) => {
      onSetFrequency([product, value]);
    },
    [product, onSetFrequency]
  );

  const onNumberOfShipmentsChange = useCallback(
    (event) => {
      onSetNumberOfShipments([product, event.target.value]);
    },
    [product, onSetNumberOfShipments]
  );

  const labelNumberOfShipments = intl.formatMessage({
    id: "FS2.CardShipmentProduct.labelNumberOfShipments",
  });

  const ariaLabelCloseButton = intl.formatMessage(
    {
      id: "FS2.CardShipmentProduct.screenReaderLabel_closeButton",
    },
    {
      product: productLabel,
    }
  );

  const getMessage = (id) => intl.formatMessage({ id });

  return (
    <Container>
      <FlexWrapper>
        <ProductIcon as={productIcon} />
        {isFSLikeProfile(profile) && (
          <CloseButton
            aria-label={ariaLabelCloseButton}
            data-testid={`${name}_numberOfShipments_${product}_closeButton`}
            onClick={() => onRemoveProduct(product)}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </FlexWrapper>
      <HeadlineContainer aria-hidden="true">
        <ProductText>
          <ProductLabel
            aria-hidden="true"
            className={isTitleTooLong(maxTitleLength) ? "expand-me" : ""}
            $maxTitleLength={maxTitleLength}
          >
            {getHeadline(productLabel, regionOptions)}
          </ProductLabel>
          <ProductInfo>
            <ProductInfoList>
              {productInfo.map((i) => (
                <ProductInfoListItem key={i}>
                  <ProductInfoListText>{i}</ProductInfoListText>
                </ProductInfoListItem>
              ))}
            </ProductInfoList>
          </ProductInfo>
        </ProductText>
      </HeadlineContainer>
      <InputContainer>
        <NumberOfShipmentsContainer>
          <NumberOfShipments
            dataAriaLabel={`${productLabel}, ${labelNumberOfShipments}`}
            dataTestid={`shipmentProduct_${product}_numberOfShipments`}
            data-tracking={`${trackingPath}.numberOfShipments`}
            dataId={`shipmentProduct_${product}_numberOfShipments`}
            isRequired
            name={`shipmentProduct_${product}_numberOfShipments`}
            onDhlInput={onNumberOfShipmentsChange}
            value={FF.getValue(numberOfShipments)}
            validation={FF.case(
              {
                valid: () => ({
                  type: "valid",
                }),

                invalid: (_, { feedbackMessageId, rule }) => ({
                  type: "invalid",
                  message: intl.formatMessage(
                    { id: feedbackMessageId },
                    {
                      minValue: rule.min,
                      maxValue: rule.max,
                    }
                  ),
                }),

                _: always(undefined),
              },
              numberOfShipments
            )}
            variant={{
              label: labelNumberOfShipments,
              placeholder: labelNumberOfShipments,
              type: "static",
            }}
          />
        </NumberOfShipmentsContainer>

        <FrequencySelector
          intl={intl}
          isInvalid={FF.isInvalid(frequency)}
          options={frequencyOptions}
          product={product}
          selectedFrequency={FF.getValue(frequency)}
          setSelectedFrequency={onFrequencyChange}
          trackingPath={trackingPath}
          validationFeedback={FF.getFeedback(frequency)}
        />

        {regionOptions.length > 1 && (
          <RegionOptions
            intl={intl}
            isInvalid={isRegionInvalid}
            onChange={onRegionChange}
            options={regionOptions}
            product={product}
            regions={regions}
            selectedRegions={selectedRegions}
            trackingPath={trackingPath}
          />
        )}

        {extraOptions}

        {noMatches && (
          <NoMatches
            dataTestid={`${REROUTING_DATA_TESTIDS.threshold.notice}-${product}`}
          >
            <NoMatchesIcon />
            {abTesting === AB_TESTING_CAMPAIGNS.REROUTING_THRESHOLD_COPY ? (
              <React.Fragment>
                Your submission does not meet our minimum threshold for a{" "}
                {productLabel} business account. For less frequent shipments,
                continue to{" "}
                {getReroutingThresholdLink(
                  reroutingThresholdData,
                  product,
                  getMessage,
                  openLinksInSameTab
                )}
              </React.Fragment>
            ) : (
              <FormattedMessage
                id={getReroutingThresholdMessage(
                  reroutingThresholdData,
                  parcelOption,
                  product,
                  profile
                )}
                values={{
                  alternativeService: getReroutingThresholdLink(
                    reroutingThresholdData,
                    product,
                    getMessage,
                    openLinksInSameTab
                  ),
                  parcelOption: parcelOption
                    ? intl.formatMessage({
                        id: `FS2.Rerouting.ParcelOptionsProductDetails${parcelOption
                          .charAt(0)
                          .toUpperCase()}${parcelOption.slice(1)}`,
                      })
                    : null,
                  product: productLabel,
                  thresholds: [
                    intl
                      .formatMessage(
                        {
                          id: "FS2.CardShipmentProduct.frequencyWithShipment",
                        },
                        {
                          numberOfShipments: noMatches.threshold.find(
                            propEq("timeInterval", FF.getValue(frequency))
                          ).numberOfShipments,
                          frequency: intl.formatMessage({
                            id: `FS2.CardShipmentProduct.frequencyOptionLabel_${FF.getValue(
                              frequency
                            )}`,
                          }),
                        }
                      )
                      .toLowerCase(),
                  ].join(" "),
                }}
              />
            )}
          </NoMatches>
        )}

        {showReroutingRegionNotice && !noMatches && (
          <ReroutingRegionNotice
            dataTestid={`${REROUTING_DATA_TESTIDS.region.notice}-${product}`}
          >
            {showReroutingRegionForGlobal && (
              <FormattedMessage
                id={getReroutingRegionMessage(
                  reroutingRegionData,
                  product,
                  REGIONS.GLOBAL,
                  REGIONS.DOMESTIC
                )}
                values={{
                  alternativeService: getReroutingRegionLink(
                    reroutingRegionData,
                    product,
                    REGIONS.GLOBAL,
                    getMessage,
                    openLinksInSameTab
                  ),
                  product: productLabel,
                }}
              />
            )}
            {showReroutingRegionForDomestic && (
              <FormattedMessage
                id={getReroutingRegionMessage(
                  reroutingRegionData,
                  product,
                  REGIONS.DOMESTIC,
                  REGIONS.GLOBAL
                )}
                values={{
                  alternativeService: getReroutingRegionLink(
                    reroutingRegionData,
                    product,
                    REGIONS.DOMESTIC,
                    getMessage,
                    openLinksInSameTab
                  ),
                  product: productLabel,
                }}
              />
            )}
          </ReroutingRegionNotice>
        )}
      </InputContainer>
    </Container>
  );
};

ProductDetailsCard.propTypes = {
  abTesting: PropTypes.string,
  extraOptions: PropTypes.object,
  frequency: PropTypes.object,
  frequencyOptions: PropTypes.array.isRequired,
  maxTitleLength: PropTypes.number,
  intl: PropTypes.object.isRequired,
  isRegionInvalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noMatches: PropTypes.object,
  numberOfShipments: PropTypes.object,
  onRemoveProduct: PropTypes.func.isRequired,
  onSetFrequency: PropTypes.func.isRequired,
  onSetNumberOfShipments: PropTypes.func.isRequired,
  onSetRegion: PropTypes.func.isRequired,
  openLinksInSameTab: PropTypes.bool,
  parcelOption: PropTypes.string,
  product: PropTypes.string.isRequired,
  productIcon: PropTypes.elementType.isRequired,
  productInfo: PropTypes.array.isRequired,
  productLabel: PropTypes.string.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  regions: PropTypes.object,
  regionOptions: PropTypes.array.isRequired,
  reroutingRegionData: PropTypes.object,
  reroutingThresholdData: PropTypes.object,
  selectedRegions: PropTypes.array.isRequired,
  trackingPath: PropTypes.string.isRequired,
};
