import styled, { keyframes } from "styled-components";
import {
  DhlCard,
  DhlText,
  DhlList,
  DhlListItem,
  DhlInputField,
} from "@dhl-official/react-library";
import {
  Cancel as IconCancel,
  Exception as IconException,
} from "@dhl-official/icons";
import { STYLES_VIEWPORTS_VALUES } from "../../../../utils/constants";
import { calculateTitleMinHeight } from "../../helpers";

const APPEAR_FROM_TOP = keyframes`
  from {
    transform: translateY(-10px) scale(0.9);
    opacity: 0;
  }

  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

export const Container = styled(DhlCard)`
  display: flex;
  height: 100%;
  flex: 1;

  & .card-container {
    height: 100%;
    width: 100%;
  }

  & .card-body {
    display: flex;
    flex-direction: column;
    border: solid 1px transparent;
    border-color: ${({ hasError }) =>
      hasError ? `var(--dui-color-red-500)` : undefined};
    border-radius: var(--dui-size-radius-md);
    height: 100%;
    padding: var(--dui-size-space-8x);
    position: relative;
    user-select: none;
    gap: var(--dui-size-space-8x);
  }
`;

export const HeadlineContainer = styled.div`
  align-items: flex-start;
  display: flex;
  min-height: 100px;
  flex-direction: column;
`;

export const ProductIcon = styled.svg.attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  background-color: var(--dui-color-gray-50);
  height: 64px;
  width: 64px;
  padding: var(--dui-size-space-4x);
  border-radius: var(--dui-size-radius-sm);
`;

export const Small = styled.small`
  display: block;
  font-size: 1em;
  font-weight: 400;

  :before {
    content: "(";
  }

  :after {
    content: ")";
  }
`;

export const ProductLabel = styled(DhlText).attrs({ weight: 700 })`
  & span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.75rem;
    word-break: break-word;
    margin-bottom: var(--dui-size-space-4x);
  }
  @media screen and (min-width: ${STYLES_VIEWPORTS_VALUES.MEDIUM}px) and (max-width: ${STYLES_VIEWPORTS_VALUES.LARGE}px) {
    &.expand-me span {
      min-height: ${({ $maxTitleLength }) =>
        calculateTitleMinHeight($maxTitleLength, "1.75rem", "1.57")};
    }
  }
`;

export const ProductText = styled.div`
  flex: 1;
`;

export const ProductInfo = styled.div`
  ul {
    line-height: initial;
  }

  li {
    color: var(--dui-color-gray-600);
    font-size: var(--dui-size-font-sm);
    margin-bottom: 0;
    margin-left: var(--dui-size-space-7x);

    &::before {
      width: 10px;
    }

    &::after {
      background-color: var(--dui-color-gray-600);
      height: 3px;
      margin-left: 0px;
      width: 3px;
    }
  }
`;

export const ProductInfoList = styled(DhlList)`
  .dhl-list {
    gap: 0;
    padding-inline-start: 15px;
  }
`;

export const ProductInfoListItem = styled(DhlListItem)``;

export const ProductInfoListText = styled(DhlText)`
  & span {
    font-size: 1.3rem;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 1;
`;

export const NumberOfShipmentsContainer = styled.div`
  margin-bottom: var(--dui-size-space-8x);
`;

export const NumberOfShipments = styled(DhlInputField).attrs({
  autoComplete: "off",
  type: "text",
})`
  input {
    appearance: none;
    -webkit-appearance: none;
  }
`;

export const ReroutingRegionNotice = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  p {
    margin-top: var(--dui-size-space-8x);
    text-align: left;
  }
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  border: 0;
  color: var(--dui-color-black-400);
  cursor: pointer;
  display: flex;
  outline-offset: 2px;
  outline: solid 2px transparent;
  padding: 0;
  right: var(--dui-size-space-8x);
  top: var(--dui-size-space-8x);
  transition: outline-color var(--dui-duration-default);

  &:focus-visible {
    outline-color: var(--dui-color-black-400);
  }
`;

export const NoMatches = styled(DhlText).attrs({
  isParagraph: true,
  size: "sm",
})`
  p {
    animation: ${APPEAR_FROM_TOP};
    animation-duration: var(--dui-duration-default);
    margin: var(--dui-size-space-7x) 0;
    padding-left: calc(20px + var(--dui-size-space-3x));
    position: relative;
    text-align: left;
  }
`;

export const NoMatchesIcon = styled(IconException).attrs({
  "aria-hidden": true,
  role: "presentation",
})`
  color: var(--dui-color-gray-400);
  height: 20px;
  left: 0;
  margin-top: 4px;
  position: absolute;
  top: 0;
  width: 20px;
`;

export const CloseIcon = styled(IconCancel)`
  width: 20px;
  height: 20px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
