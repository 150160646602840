import { PARCEL_OPTIONS } from "../../../../utils/constants";

export const options = Object.values(PARCEL_OPTIONS);
export const translations = {
  [PARCEL_OPTIONS.STANDARD]: {
    title: "FS2.CardShipmentProduct.parcelOptionsStandard",
    subtitle: "FS2.CardShipmentProduct.parcelOptionsStandardExplanation",
  },
  [PARCEL_OPTIONS.EXPEDITED]: {
    title: "FS2.CardShipmentProduct.parcelOptionsExpedited",
    subtitle: "FS2.CardShipmentProduct.parcelOptionsExpeditedExplanation",
  },
};
