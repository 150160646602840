import styled from "styled-components";
import { DhlCheckbox, DhlText } from "@dhl-official/react-library";

export const Container = styled.div`
  margin: var(--dui-size-space-10x) 0;
`;

export const List = styled.ul.attrs({
  "data-testid": "parcel-urgency-options",
})`
  line-height: initial;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
`;

export const Info = styled(DhlText).attrs({ isParagraph: true, size: "sm" })`
  & p {
    margin-bottom: var(--dui-size-space-7x);
  }
`;

export const Item = styled.li`
  margin-bottom: var(--dui-size-space-7x);
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Option = styled(DhlCheckbox).attrs({
  size: "md",
})`
  & > div > label.md {
    height: auto;
  }
`;

export const OptionContent = styled.div``;

export const Title = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

export const SubTitle = styled(DhlText).attrs({
  size: "sm",
  weight: 400,
  isParagraph: true,
})`
  & p {
    color: var(--dui-color-gray-600);
    margin: 0;
  }
`;
